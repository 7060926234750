<template>
  <div
    class="d-flex flex-column flex-lg-row max-h-md-view"
    style="row-gap: 2rem; column-gap: 1rem;"
  >
    <div
      id="contact-container"
      class="overflow-y-md-auto rounded-lg"
      style="min-width: 24rem; min-height: 30rem"
      :style="isMobile ? 'max-width: 100%' : 'max-width: 24rem'"
    >
      <contact-info />

      <app-collapse type="margin">
        <contact-details />

        <note v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_NOTE])" />

        <thread-list />

        <thread-assigned-list />
      </app-collapse>
    </div>

    <div id="right-side"
         class="flex-grow-1 overflow-y-md-auto rounded-lg"
    >
      <div v-show="!threadLoading">
        <right-toolbar />

        <funnel-points-breadcrumbs />

        <thread-tabs class="mt-50" />
      </div>
      <div v-show="threadLoading">
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOAD_CURRENT_CONTACT } from '@/@constants/mutations'
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import ThreadTabs from './components/ThreadTabs.vue'
import FunnelPointsBreadcrumbs from './components/FunnelPointsBreadcrumbs.vue'
import ContactDetails from './components/ContactDetails.vue'
import ThreadList from './components/ThreadList.vue'
import ThreadAssignedList from './components/ThreadAssignedList.vue'
import RightToolbar from './components/RightToolbar.vue'
import ContactInfo from './components/ContactInfo.vue'
import Note from './components/Note.vue'

export default {
  components: {
    FunnelPointsBreadcrumbs,
    ContactInfo,
    ThreadTabs,
    Note,
    ContactDetails,
    ThreadList,
    ThreadAssignedList,
    RightToolbar,
  },
  data: () => ({
    loading: false,
    isMobile: false,

    router,
  }),
  watch: {
    async $route(to, from) {
      if (to.params.contactId !== from.params.contactId) await this.loadCurrentContact()
    },
  },
  computed: {
    ...mapGetters({
      threadLoading: 'contact/threadLoading',
    }),
  },
  async mounted() {
    this.loading = true

    try {
      await this.loadCurrentContact()
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

      await this.router.push({ name: 'contacts' })
    }

    this.loading = false
  },
  created() {
    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile)
  },
  methods: {
    ...mapActions({
      loadCurrentContact: `contact/${LOAD_CURRENT_CONTACT}`,
    }),

    checkIfMobile() {
      this.isMobile = window.innerWidth <= 991 // Bootstrap's mobile breakpoint
    },
  },
}
</script>
